<template>

	<!-- Insurance actions Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0,}">

		<template #title>
			<a-row type="flex" align="middle">

				<a-col :span="24" :md="3" :xs="12">
					<a-button type="primary"
						@click="showModal"
					>
						Add Insurance
					</a-button>
				</a-col>
				<a-col :span="24" :md="2" :xs="12">

					<a-button type="primary" icon="download"
						@click="download"/>
				</a-col>
				<a-col :span="24" :md="8" class="add-item-col">
					<!-- Header Search Input -->
					<a-input-search class="header-search"  placeholder="Search for request..." v-model="searchValue" @input="tableSearch">

					</a-input-search>
					<!-- / Header Search Input -->
				</a-col>
			</a-row>
			<MainModal
					v-if="visible"
					:title="modalTitle"
				 	@handleOk="modalHandleOk"
					:handle-cancel="modalHandleCancel"
				>
				<MainForm ref="formFields" :formFields="InsuranceInputs" :title="modalTitle"></MainForm>
			</MainModal>
		</template>

	</a-card>
	<!-- Insurance actions Card -->

</template>

<script>
import MainModal from '../Modal/MainModal.vue';
import MainForm from '../Forms/MainForm.vue';
import { mapActions } from 'vuex'
import { mapState } from 'vuex' 
import { jsontoexcel } from "vue-table-to-excel";
import debounce from 'lodash/debounce'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata   } from "firebase/storage";

	export default ({
		components: {
		  MainModal, MainForm
		},
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
			permissions: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				rootPath: '',
				storage: null,
				fileName: null,
				visible: false,
				modalTitle: "Add Work Request",
				InsuranceInputs: [
					{ 	name: 'location', label: 'Choose File', type:'uploadFile', rules: ['required']},
					{ name: 'issue', label: 'Issue', placeholder: 'Enter Title', type:'text', rules: ['required']},
					{ name: 'area', label: 'Area', type:'selectBox', 'options': [{value: 'Common Area', text: 'Common Area'}, {value: 'In-Unit', text: 'In-Unit'}], rules: ['required']},
      		{ name: 'details', label: 'Details', placeholder:'Enter Details', type:'textarea', rows:6, rules: []},
      		{ name: 'owner', label: 'Reported By', type:'searchSelect', rules: ['required']},
      	],
				searchValue: ''
			}
		},
		computed: {
			...mapState({
				membersInfo: state => state.building.membersInfo,
				user: state => state.auth.user,
				routesByRole: state => state.auth.routesByRole,


			}),
	    formattedDate() {
	      const today = new Date();
	      const year = today.getFullYear();
	      const month = String(today.getMonth() + 1).padStart(2, '0');
	      const day = String(today.getDate()).padStart(2, '0');
	      return `${month}/${day}/${year}`;
	    },
		},

		async mounted() {
			//get getDataPermission from permissions object
			this.storage = getStorage();
			this.rootPath = this.$store.getters.user.building_id + '/insurance/' ;
			await this.getMembersInformation();
			const inputIndex = this.InsuranceInputs.indexOf(this.InsuranceInputs.find(el => el.name === 'owner'));

			this.InsuranceInputs[inputIndex].membersInfo =  this.membersInfo;
		},
		methods: {
			
			download() {
				const dataDownload = this.data.map((item) => {
					return {
						date: item.date,
						issue : item.issue,
						area : item.area,
						status: item.status,
						details: item.details,
						owner: item.owner.name + " - " + item.owner.apt,
						owner_email: item.owner.email,
					};
				});
				//get title from columns object into new array
				const head = this.columns.map((item) => item.title);
				const fileName = "Work Requests -" + this.formattedDate + '.csv';
				console.log("download", dataDownload, head, fileName);
				jsontoexcel.getXlsx(dataDownload, head, fileName);
				},

		  showModal() {
		    this.visible = true
		  },
			modalHandleCancel() {
				this.visible = false
			},
			async modalHandleOk(handleOnFinish) {
				try {
					let isValid = this.$refs.formFields.validate()
					if(!isValid){
						return;
					}
					const success =  await this.UploadedFile();
					if (success) {
						const fileOptions = this.$refs.formFields.fileOptions;
						let fileName = fileOptions[0].file.name;
						const location = this.rootPath + '/' + fileName;
						const storageRef = ref(this.storage, location);
						let formFields = this.$refs.formFields.formData;
						formFields.storageRef = storageRef;
						formFields.location = location;
						formFields.storage = this.storage;
						let member_id = this.membersInfo[formFields.owner].member_id;
						formFields.owner = {
							apartment:this.membersInfo[formFields.owner].apartment,
							name: this.membersInfo[formFields.owner].name,
							email: this.membersInfo[formFields.owner].email,
						} 
						formFields.unit = this.membersInfo[formFields.owner].apartment;
						let res = await this.addInsurance({insurance: {...formFields, ...{status: "Open", date: this.formattedDate, member_id: member_id}}})
						if(res) {
							this.$refs.formFields.onFinish(true);
							this.visible = false;
							await this.prepURL()
						} else {
							console.log('modalHandleOk false', res)
							this.$refs.formFields.onFinish(false);
						}
					} else {
							console.log('UploadedFile false', success)
							this.$refs.formFields.onFinish(false);
						}
				} catch (e) {
					console.log('modalHandleOk error', e)
					this.$refs.formFields.onFinish(false);
				} finally {
					handleOnFinish()
				}
		  },
		  async UploadedFile() {
				const storage = getStorage();
				const payload = this.$refs.formFields.formData;
				const fileOptions = this.$refs.formFields.fileOptions;
				console.log('UploadedFile fileOptions', fileOptions)
				
				for (let i = 0; i < fileOptions.length; i++) {
					let fileName = fileOptions[i].file.name;
					const metadata = {
							contentType: fileOptions[i].file.type,
							customMetadata : {
							documentType: payload.type,
							details: payload.details
							}
						};
					console.log('UploadedFile fileName', fileName)
					

					const location = this.rootPath + '/' + fileName;
					const newStorageRef = ref(storage, location);
					const uploadTask = uploadBytesResumable(newStorageRef, fileOptions[i].file, metadata);

					await new Promise((resolve, reject) => {
						uploadTask.on('state_changed',
							(snapshot) => {
								// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								console.log('Upload is ' + progress + '% done');
								switch (snapshot.state) {
									case 'paused':
										console.log('Upload is paused');
										break;
									case 'running':
										console.log('Upload is running');
										break;
								}
							},
							(error) => {
								console.log("error", error);
								switch (error.code) {
									case 'storage/unauthorized':
										reject(false);
										break;
									case 'storage/canceled':
										reject(false);
										break;
									case 'storage/unknown':
										reject(false);
										break;
									default:
										reject(error);
								}
							},
							() => {
								fileOptions[i].onSuccess(uploadTask.snapshot, fileOptions[i].file);
								resolve(true);
							}
						);
					});
				}
				return true;
		},
			tableSearch() {
				let debouncedSearch = debounce(() => {
				  this.filterInsuranceData({searchValue: this.searchValue})
				}, 500)
      	debouncedSearch()
			},
			...mapActions('insurance', ['addInsurance', 'filterInsuranceData', 'prepURL']),

			...mapActions({
				getMembersInformation: 'building/getMembersInformation'
			})
		  },
	})

</script>

<style media="screen">
	@media screen and (max-width: 767px) {
		.add-item-col {
			padding-top: 20px; /* add desired padding value */
		}
	}
</style>
