<template>

	<!-- Insurance Table Card --> 
	
	<a-card :bordered="false" class="header-solid h-full" :loading="this.is_loading" :bodyStyle="{padding: 0,}">
		<a-row v-if="!permissionsInfo.getAllDataPermission" >
			<a-col :span="24" >
				<a-card v-if="!has_file || has_file === ''" class="mb-24">
					<h4>Please upload your current Certificate of Insurance (COI) or Insurance Declarations Page</h4>
					<p>Our system would remind you to renew your insurance, and notify you if your insurance is not compliant with the building requreiments</p>
					<a-button type="primary" @click="showModal(data[0])">Upload Insurance</a-button>
				</a-card>
			</a-col>
		</a-row>
		<a-table :columns="columns" :data-source="data" :pagination="true">


			<template slot="func" slot-scope="func">
				<div class="transaction-info">
					<h6 class="m-0">{{ func.job }}</h6>
					<p class="m-0 font-regular text-muted">{{ func.department }}</p>
					
				</div>

			</template>


			<template slot="status" slot-scope="row">
				<a-dropdown :trigger="['click']">
					<a-tag class="tag-status"
						:class="[row.status=='In Review' ? 'ant-tag-muted' : '',
								row.status=='Missing File' ? 'ant-tag-red' : '',
								row.status=='Renewal Required' ? 'ant-tag-warning' : '',
								row.status=='Not Compliant' ? 'ant-tag-red' : '',
								row.status=='Compliant' ? 'ant-tag-success' : '']"
						@click="e => e.preventDefault()">
					{{ row.status }} <a-icon type="down" />
					</a-tag>  
					<a-menu v-if="permissionsInfo.getEditAllPermission" slot="overlay">
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Missing File', newDetails='Please upload insurance file')">Missing File</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='In Review', newDetails='File in review, status would be updated soon')">In Review</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Renewal Required', newDetails='New insurance file required')">Renewal Required</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Not Compliant', newDetails='')">Not Compliant</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;" v-on:click="updateStatus(row, newStats='Compliant', newDetails='')">Compliant</a>
					</a-menu-item>
					</a-menu>
				</a-dropdown>
			</template>

			<template slot="actionsBtn" slot-scope="row">
				<div class="icon-container" style="display: flex; justify-content: flex-end;">
					
					<a href="javascript:;" v-if="permissionsInfo.getEditAllPermission" v-on:click="showEditModal(row)">
						<v-icon size="25">edit</v-icon>
					</a>
					<a href="javascript:;" v-if="row.location !== ''" v-on:click="downloadItem(row)" style="margin-left: 10px;">
						<svg-icon size="25" type="mdi" :path="path"></svg-icon>
					</a>
					<a href="javascript:;" v-if="row.location !== ''" v-on:click="DeleteRow(row)" style="margin-left: 10px;">
						<v-icon size="25">delete</v-icon>
					</a>
					<a href="javascript:;" v-if="!row.location || row.location === ''" v-on:click="showModal(row)" style="margin-left: 10px;">
						<v-icon size="25">upload</v-icon>
					</a>
				</div> 
				<MainModal
					v-if="edit_visible"
					:title="modalEditTitle"
				 	@handleOk="modalEditHandleOk"
					:handle-cancel="modalEditHandleCancel"
				>
				<MainForm ref="formEditFields" :formFields="InsuranceEditInputs" :title="modalEditTitle"></MainForm>
			</MainModal>
				<MainModal
					v-if="visible"
					:title="modalTitle"
				 	@handleOk="modalHandleOk"
					:handle-cancel="modalHandleCancel"
				>
				<MainForm ref="formFields" :formFields="InsuranceInputs" :title="modalTitle"></MainForm>
			</MainModal>
			</template>



		</a-table>
	</a-card>
	<!-- / Insurance Table Card -->

</template>

<script>
import MainModal from '../Modal/MainModal.vue';
import MainForm from '../Forms/MainForm.vue';
import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
import Axios from 'axios';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata   } from "firebase/storage";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiDownload } from '@mdi/js';
import moment from 'moment';

Axios.defaults.withCredentials = false;

	export default ({
		components: {
    MainModal,
    MainForm,
	SvgIcon,
	},
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				path: mdiDownload,
				visible: false,
				edit_visible: false,
				url: '',
				modalTitle: "Add Insurance File",
				modalEditTitle: "Edit Details",
				InsuranceInputs: [
					{ 	name: 'location', label: 'Choose Insurance File', accept: ".pdf", type:'uploadFile', rules: ['required']},
      				],
					
				InsuranceEditInputs: [
					{ 	name: 'details', label: 'Edit details', type:'textarea', rules: []},
					{ 	name: 'expiration_date', label: 'Edit Expiration Date', type:'date', rules: []},
      				],
				rowDate: '',
				rowStatus: '',
				rowKey: '',
				unit: '',
				editRow: null,
				originalDetails: '',
				user_name: '',
				has_file: true,
				is_loading: true,
			}
		},
		async mounted() {
			this.storage = getStorage();
			this.rootPath = this.$store.getters.user.building_id + '/insurance' ;
			this.building_id = this.$store.getters.user.building_id;
			this.user_email = this.$store.getters.user.email;
			this.user_name = this.$store.getters.user.displayName;

		},
		watch: {
			data: {
				handler: function (val, oldVal) {
					this.has_file = this.data.length > 0 && Object.prototype.hasOwnProperty.call(this.data[0], 'location') && this.data[0].location !== '';
					this.is_loading = false;
				},
				deep: true
			}
		}
		,
		computed: {
			...mapState({
				membersInfo: state => state.building.membersInfo,
				permissionsInfo: state => state.insurance.permissionsInfo,
			}),
		},
		methods: {

	async UploadedFile() {
				const storage = getStorage();
				const payload = this.$refs.formFields.formData;
				const fileOptions = this.$refs.formFields.fileOptions;
				
				for (let i = 0; i < fileOptions.length; i++) {
					let fileName = fileOptions[i].file.name;
					const metadata = {
							contentType: fileOptions[i].file.type,
							customMetadata : {
							documentType: payload.type,
							details: payload.details,
							fileName: fileName,
							}
						};
					console.log('UploadedFile fileName', fileName)
					

					const location = this.rootPath + '/' + this.unit + '/' + fileName;
					const newStorageRef = ref(storage, location);
					const uploadTask = uploadBytesResumable(newStorageRef, fileOptions[i].file, metadata);

					await new Promise((resolve, reject) => {
						uploadTask.on('state_changed',
							(snapshot) => {
								// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								switch (snapshot.state) {
									case 'paused':
										console.log('Upload is paused');
										break;
									case 'running':
										console.log('Upload is running');
										break;
								}
							},
							(error) => {
								console.log("error", error);
								switch (error.code) {
									case 'storage/unauthorized':
										reject(false);
										break;
									case 'storage/canceled':
										reject(false);
										break;
									case 'storage/unknown':
										reject(false);
										break;
									default:
										reject(error);
								}
							},
							() => {
								fileOptions[i].onSuccess(uploadTask.snapshot, fileOptions[i].file);
								//this.has_file = true;
								resolve(true);
							}
						);
					});
					await getDownloadURL(newStorageRef)
						.then((url) => {
						this.url = url;
						})
						.catch((error) => {
						console.log('getDownloadURL', error);
						});
					console.log('UploadedFile url', this.url)
				}
				return true;
		},
		showModal(row) {
		    this.visible = true
			this.unit = row.unit
			this.rowKey = row.key
		  },
		showEditModal(row) {
		    this.edit_visible = true
			this.unit = row.unit
			this.rowKey = row.key
			this.InsuranceEditInputs.forEach((inputRow, index) => {
				try {
					if (inputRow.name === 'expiration_date') {
							const rowDate = row[this.InsuranceEditInputs[index].name];
							const momentRowDate = moment(rowDate, 'MM/DD/YYYY');
							const date = momentRowDate.isValid() ? momentRowDate : null;
							this.InsuranceEditInputs[index].value = date
						} else {
							this.InsuranceEditInputs[index].value = row[this.InsuranceEditInputs[index].name]
						}
				} catch (e) {
					console.log('showEditModal e', e)
				}
			});
		  },
		modalHandleCancel() {
			this.visible = false
		},

		
		modalEditHandleCancel() {
			this.edit_visible = false
		},

	downloadItem(row) {
				Axios.get(row.url, { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data])
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = row.fileName
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
			},
		async DeleteRow(row) {
		if(confirm("Are you sure you want to delete the existing file? This action cannot be undone and you would need to upload a new file")){
			console.log("deleting", row.key);

			try {
				//success = await this.duplicateExistingFile(row)
				let newDetails = 'Please upload insurance file'
				let new_expiration_date = null
				let newStats = 'Missing File'
				let fileDeleteTimeSTR = new Date().toISOString()
				let res = await this.updateInsurance({insurance: {status: newStats, details: newDetails, expiration_date: new_expiration_date, key: row.key, location: '', fileName: '', url: '', fileDeleteTimeSTR: fileDeleteTimeSTR}, building_id: this.building_id, user_email: this.user_email, user_name: this.user_name})
				this.has_file = false;
			} catch (e) {
					console.log('DeleteRow error', e)
				}
		}
		},

		async modalEditHandleOk(handleOnFinish) {
			try {
					let isValid = this.$refs.formEditFields.validate()
					if(!isValid){
						return;
					}
					let formEditFields = this.$refs.formEditFields.formData;
					formEditFields.key = this.rowKey;
					formEditFields.unit = this.unit;
					let res = await this.updateInsurance({insurance: formEditFields, building_id: this.building_id, user_email: this.user_email, user_name: this.user_name})
					if(res) {
						this.$refs.formEditFields.onFinish(true);
						this.edit_visible = false;
					} else {
						this.$refs.formEditFields.onFinish(false);
					}
				} catch (e) {
					console.log('modalEditHandleOk error', e)
				} finally {
					handleOnFinish()
				}
			},


		async modalHandleOk(handleOnFinish) {
				try {
					let isValid = this.$refs.formFields.validate()
					if(!isValid){
						return;
					}
					const success =  await this.UploadedFile();
					if (success) {
						this.getInsuranceSummary({building_id: this.building_id});
						const fileOptions = this.$refs.formFields.fileOptions;
						let fileName = fileOptions[0].file.name;
						const location = this.rootPath + '/' + this.unit + '/' + fileName;
						let formFields = this.$refs.formFields.formData;
						formFields.location = location;
						formFields.unit = this.unit;
						formFields.fileName = fileName;
						formFields.details = "File in review, status would be updated soon"
						let res = await this.addInsuranceFile({insurance: {...formFields, ...{status: "In Review", url: this.url, date: this.formattedDate, user_email: this.user_email , user_name: this.user_name, building_id: this.building_id}}})
						if(res) {
							this.$refs.formFields.onFinish(true);
							await this.prepURL()
							this.visible = false;
						} else {
							console.log('modalHandleOk false', res)
							this.$refs.formFields.onFinish(false);
						}
					} else {
							console.log('UploadedFile false', success)
							this.$refs.formFields.onFinish(false);
						}
				} catch (e) {
					console.log('modalHandleOk error', e)
					this.$refs.formFields.onFinish(false);
				} finally {
					handleOnFinish()
				}
		  },

		async updateStatus(row, newStats, newDetails) {
			try {
				// add warning if the newStstus is not in the list is "Missing File"
					if(newStats == "Missing File"){
						if(!confirm("Are you sure you want to change the status to Missing File? Please delete the existing file if so")){
							return
						}
					}
				let res = await this.updateInsurance({insurance: {status: newStats, details: newDetails, key: row.key}, building_id: this.building_id, user_email: this.user_email, user_name: this.user_name})
				this.getInsuranceSummary({building_id: this.building_id});
			} catch (e) {
				console.log('updateStatus error', e)
			}
		},
		...mapActions('insurance', ['updateInsurance', 'deleteInsurance', 'addInsuranceFile', 'prepURL', 'getInsuranceSummary' ]),
	},
})

</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: space-between;
}
.tag-status .anticon-down {
  display: none;
}


</style>
