<!-- 
	This is the insurance page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		<a-row v-if="permissionsInfo.getAllDataPermission" >
			<a-col :span="24" >
				<a-card class="mb-24">
					<h4>Homeowners Insurance Admin View</h4>
					<p>Here you can find the insurance statuses for all the units. As an admin, you can also upload insurance docs to any unit.</p>
				</a-card>
			</a-col>
		</a-row>
		<a-row v-if="buildingInfo.insurance_requirements" >
			<a-col :span="24" >
				<a-card class="mb-24">
					<h5> Insurance Coverage Requirements </h5>
					<p> {{ buildingInfo.insurance_requirements }}</p>
				</a-card>
			</a-col>
		</a-row>
		
		<a-row v-if="permissionsInfo.getAllDataPermission"  :gutter="24" type="flex">
			
				<a-col  :span="24"  class="mb-24">
					
					<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0,}">
						<template #title>
							<a-row type="flex" align="middle"  >
								<a-col :span="24" :md="14" :xs="18">
									<a-tag class='ant-tag-success'>Compliant: {{ insuranceSummary['Compliant'] }}</a-tag>
									
									<a-tag class='ant-tag-muted'>In Review: {{ insuranceSummary['In Review'] }}</a-tag>
									
									<a-tag class='ant-tag-warning'>Renewal Required: {{ insuranceSummary['Renewal Required'] }}</a-tag>
									
									<a-tag class='ant-tag-red'>Missing File: {{ insuranceSummary['Missing File'] }}</a-tag>
									
									<a-tag class='ant-tag-red'>Not Compliant: {{ insuranceSummary['Not Compliant'] }}</a-tag>
									
								</a-col>
								<a-col :span="24" :md="8" :xs="22" >
								<!-- Header Search Input -->
								<a-input-search class="header-search"  placeholder="Search..." v-model="searchValue" @input="tableSearch">

								</a-input-search>
								<!-- / Header Search Input -->
							</a-col>
							</a-row>
						</template>
					</a-card>
				</a-col>
				
			

		</a-row>
		<!-- 
		<a-row  :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<CardInsuranceActions
					:data="table1Data"
					:columns="table1Columns"
					:permissions="permissionsInfo">
				</CardInsuranceActions>

			</a-col>

		</a-row>
		 -->

		

		<!-- Insurance Table -->
		<a-row :gutter="24" type="flex">

			<!-- Insurance Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Insurance Table Card -->
				<CardInsuranceTable
					:data="table1Data"
					:columns="table1Columns"
				></CardInsuranceTable>
				<!-- / Insurance Table Card -->

			</a-col>
			<!-- / Insurance Table Column -->

		</a-row>
		<!-- / Insurance Table -->

	</div>
</template>

<script>

	// "Insurance" table component.
	import CardInsuranceTable from '../components/Cards/CardInsuranceTable.vue';
	import CardInsuranceActions from '../components/Cards/CardInsuranceActions.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata   } from "firebase/storage";
	
	// "Insurance" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Date Added',
			dataIndex: 'date',
			class: 'text-muted',
			sorter: (a, b) => new Date(a.date) - new Date(b.date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter

		},
		{
			title: 'Unit',
			dataIndex: 'unit',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.unit.localeCompare(b.unit), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter

		},
		{
			title: 'Status',
			scopedSlots: { customRender: 'status' },
			sorter: (a, b) => a.status.localeCompare(b.status), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Details',
			dataIndex: 'details',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Expiration Date',
			dataIndex: 'expiration_date',
			class: 'text-muted',
			sorter: (a, b) => new Date(a.date) - new Date(b.date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter

		},
		
		{
			title: '',
			scopedSlots: { customRender: 'actionsBtn' },
		},
	];

	

	export default ({
		components: {
    CardInsuranceTable,
	CardInsuranceActions
},
		data() {
			return {

				// Associating "Insurance" table columns with its corresponding property.
				table1Columns: table1Columns,
				searchValue: '',

				
			}
		},
		computed: {
			...mapState({
				table1Data: state => state.insurance.insurance,
				buildingInfo: state => state.building.buildingInfo,
				permissionsInfo: state => state.insurance.permissionsInfo, 
				insuranceSummary: state => state.insurance.insuranceSummary,
			})
		},
		methods: { 
			...mapActions({
				getInsurance: 'insurance/getInsurance',
				getBuildingInformation: 'building/getBuildingInformation',
				prepURL: 'insurance/prepURL',
				initAllowedActions: 'insurance/initAllowedActions',
				filterInsuranceData: 'insurance/filterInsuranceData',
				getInsuranceSummary: 'insurance/getInsuranceSummary',
				
			}), 
			tableSearch() {
				let debouncedSearch = debounce(() => {
				  this.filterInsuranceData({searchValue: this.searchValue})
				}, 500)
      	debouncedSearch()
			},
		},
		async mounted() {
			window.analytics.page('Insurance');
			this.building_id = this.$store.getters.user.building_id;
			await this.getBuildingInformation();
			await this.initAllowedActions();
			await this.getInsurance();
			//await this.prepURL();
			await this.getInsuranceSummary({building_id: this.building_id});
		},
	})

</script>

<style lang="scss">
.details {
	cursor: pointer;
	white-space: pre-wrap;
}
@media (max-width: 767px) {
  .ant-tag {
    display: block;
    margin-bottom: 8px;
	width: 150px
  }
}
</style>